$text-size-regular: 2rem;
$text-family-cursive: 'Patrick Hand', cursive;

:root {
  --text-size-medium: $text-size-regular;
}

.header__container {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

@mixin header__title {
  font-family: 'Mochiy Pop P One', sans-serif;
  color: white;

  text-align: center;
  text-shadow: 1px 3px 0px black;
}

.header__title--medium {
  @include header__title;
  font-size: 2rem;
}

.header__title--large {
  @include header__title;
  font-size: 3rem;
}


.page-add-players .players-list-container {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.page-add-players .players-list-container .player-name {
  @extend .single-player-score-input-container;
  @extend .form__label--medium;
}

.form__label--medium {
  color: white;
  font-family: $text-family-cursive;
  font-size: $text-size-regular;
}



@mixin form__input {
  display: block;
  font-family: $text-family-cursive;

  // START: force input 100% width
  // https://stackoverflow.com/a/16907745/1806023 */
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  // END

  margin-bottom: 1.5rem;

}

.form__input--medium {
  @include form__input;
  font-size: $text-size-regular;
}

.form__input--round {
  font-family: $text-family-cursive;
  font-size: $text-size-regular;

  text-align: center;

  border-radius: 100%;
  min-width: 1.5rem;
  max-width: 3rem;

}

.score-input__header {
  display: flex;
  justify-content: space-between;
  align-items: center;


  font-family: 'Mochiy Pop P One', sans-serif;
  font-size: 2rem;
  color: white;
  padding: 1.5rem;
  padding-bottom: 0;
}

.single-player-score-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 0.3rem dotted #fff;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
}

@mixin remove-input-number-arrows {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

}

@include remove-input-number-arrows;


.results-table {
  color: white;
  font-family: $text-family-cursive;
  font-size: 1.5rem;

  text-align: center;
  border-collapse: collapse;
  min-width: 100%;

  th > span {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-align: left;
  }

  .table-header__player-name {
    width: 3rem;
  }

  td {
    border: 0.1rem white solid;
  }
}
