:root {
  --text-size-medium: $ text-size-regular;
}

.header__container {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.header__title--medium {
  color: #fff;
  text-align: center;
  text-shadow: 1px 3px #000;
  font-family: Mochiy Pop P One, sans-serif;
  font-size: 2rem;
}

.header__title--large {
  color: #fff;
  text-align: center;
  text-shadow: 1px 3px #000;
  font-family: Mochiy Pop P One, sans-serif;
  font-size: 3rem;
}

.page-add-players .players-list-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.form__label--medium, .page-add-players .players-list-container .player-name {
  color: #fff;
  font-family: Patrick Hand, cursive;
  font-size: 2rem;
}

.form__input--medium {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  margin-bottom: 1.5rem;
  font-family: Patrick Hand, cursive;
  font-size: 2rem;
  display: block;
}

.form__input--round {
  text-align: center;
  min-width: 1.5rem;
  max-width: 3rem;
  border-radius: 100%;
  font-family: Patrick Hand, cursive;
  font-size: 2rem;
}

.score-input__header {
  color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 1.5rem 0;
  font-family: Mochiy Pop P One, sans-serif;
  font-size: 2rem;
  display: flex;
}

.single-player-score-input-container, .page-add-players .players-list-container .player-name {
  border-bottom: .3rem dotted #fff;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
}

.single-player-score-input-container:last-child, .page-add-players .players-list-container .player-name:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.results-table {
  color: #fff;
  text-align: center;
  border-collapse: collapse;
  min-width: 100%;
  font-family: Patrick Hand, cursive;
  font-size: 1.5rem;
}

.results-table th > span {
  writing-mode: vertical-rl;
  text-align: left;
  transform: rotate(180deg);
}

.results-table .table-header__player-name {
  width: 3rem;
}

.results-table td {
  border: .1rem solid #fff;
}

/*# sourceMappingURL=index.175c71da.css.map */
